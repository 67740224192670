import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

//MUI-ICONS
import Checkbox from '@mui/material/Checkbox';

const CalculationParamItem = ({id, label, defaultValue, units, onItemChange}) => {
  const [isEditable, setEditable] = useState(false);
  const [checked, setChecked] = useState(true);
  const [value, setValue] = useState(defaultValue);
  const handleChecked = (e) => setChecked(e.target.checked);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditable(false);
      setValue(Number(e.target.value));
      setChecked(true);
    }
  };
  const handleValueChange = (e) => setValue(Number(e.target.value));

  useEffect(() => {
    !checked ? onItemChange(id, null) : onItemChange(id, value);
  }, [checked, value]);

  return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
    <Checkbox checked={checked} onChange={handleChecked} />
    <Box id='container-marta' sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
        <Typography sx={{fontWeight: 'bold', color: !checked ? 'action.disabled' : undefined}}>{label} ({units}) :</Typography>
        {
          isEditable
            ? <TextField type='number' size='small' value={value} onChange={handleValueChange} onKeyDown={handleKeyDown} sx={{maxWidth: 200}}/>
            : <Box onClick={() => setEditable(true)} sx={{width: 100}}>
              <Typography sx={{ml: 1.75, color: !checked ? 'action.disabled' : undefined}} >{defaultValue}</Typography>
            </Box>
        }
      </Box>
    </Box>
  </Box>;
};

CalculationParamItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
  onItemChange: PropTypes.func.isRequired
};

CalculationParamItem.defaultProps = {};

export default CalculationParamItem;