import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';

import IcAprovechamiento from './components/icons/categories/IcAprovechamiento';
import IcSelvicola from './components/icons/categories/IcSelvicola';
import IcRegeneracion from './components/icons/categories/IcRegeneracion';
import IcOtras from './components/icons/categories/IcOtras';
import IcInfraestructuras from './components/icons/categories/IcInfraestructuras';
import IcContraIncendios from './components/icons/categories/IcContraIncendios';
import ChairIcon from '@mui/icons-material/Chair';
import ForestIcon from '@mui/icons-material/Forest';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import LayersIcon from '@mui/icons-material/Layers';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const SPECIES_IMAGES = {
  22: 'assets/ic-arbol_Pinus-radiata.svg',
  44: 'assets/ic-arbol_roble.svg',
  42: 'assets/ic-arbol-default.svg',
  65: 'assets/ic-arbol_eucalipto-nitens.svg',
  27: 'assets/ic-arbol_Pinus-pinaster.svg',
  29: 'assets/ic-arbol_Pinus-pinaster.svg',
  63: 'assets/ic-arbol-default.svg',
  62: 'assets/ic-arbol_eucalipto-otro.svg',
  default: 'assets/ic-arbol-default.svg',
};

export const STATUS_COLORS = {
  completed: '#0d7e66',
  certification_pending: '#51B2F9',
  in_procedure: '#a6862f',
  draft: '#2C2C30',
  disabled: '#F44D4A',
  DEFAULT: '#888'
};

export const MAP_STYLES = [
  {
    label: 'IGN',
    thumbnail: 'mapstyles/igo.png',
    id: 'mapstyles/igo.json'
  },
  {
    label: 'PNOA',
    thumbnail: 'mapstyles/pnoa.png',
    id: 'mapstyles/pnoa.json'
  },
  {
    id: 'pnoa-historico',
    label: 'PNOA Histórico',
    thumbnail: 'mapstyles/pnoa.png'
  }
];

export const PNOA_YEARS = [
  {
    value: 2007,
    label: '2007'
  },
  {
    value: 2009,
    label: '2009'
  },
  {
    value: 2011,
    label: '2011'
  },
  {
    value: 2014,
    label: '2014'
  },
  {
    value: 2017,
    label: '2017'
  },
];

export const FILTERS_ORDER = [
  'done',
  'species',
  'years',
  'priority',
  'sxpublica'
];

export const LEFT_DRAWER_WIDTH_INT = 500;
export const RIGHT_DRAWER_WIDTH_INT = 600;
export const LEFT_DRAWER_WIDTH = `${LEFT_DRAWER_WIDTH_INT}px`;
export const RIGHT_DRAWER_WIDTH = `${RIGHT_DRAWER_WIDTH_INT}px`;
export const MINI_SIDE_PANEL_WIDTH = 45;

export const MAP_OFFSET = `${LEFT_DRAWER_WIDTH_INT + MINI_SIDE_PANEL_WIDTH}px`;
export const MAP_OFFSET_WITH_RIGHT_PANEL = `${LEFT_DRAWER_WIDTH_INT + MINI_SIDE_PANEL_WIDTH + RIGHT_DRAWER_WIDTH_INT}px`;

export const ROLES_LABEL = {
  ADMIN: 'ADMIN',
  FIELD_TECHNICAL: 'FIELD_TECHNICAL',
  BOARD: 'BOARD',
  COMMONER: 'COMMONER'
};

export const rolesWithEditPermissions = [
  ROLES_LABEL.ADMIN,
  ROLES_LABEL.FIELD_TECHNICAL,
  ROLES_LABEL.BOARD
];

export const VIEW_PERMISSIONS = {
  COMMONER_LIST: 'commonerList',
  EDIT_ANNUAL_PLAN: 'editAnnualPlan',
  BOOK_ACCOUNT: 'bookAccount',
  BATCH_LIST: 'batchList'
};

export const PERMISSIONS = {
  ADMIN: [VIEW_PERMISSIONS.COMMONER_LIST, VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, VIEW_PERMISSIONS.BOOK_ACCOUNT, VIEW_PERMISSIONS.BATCH_LIST],
  FIELD_TECHNICAL: [VIEW_PERMISSIONS.COMMONER_LIST, VIEW_PERMISSIONS.EDIT_ANNUAL_PLAN, VIEW_PERMISSIONS.BOOK_ACCOUNT, VIEW_PERMISSIONS.BATCH_LIST],
  BOARD: [VIEW_PERMISSIONS.COMMONER_LIST, VIEW_PERMISSIONS.BOOK_ACCOUNT],
  COMMONER: []
};

export const ACTIONS_ID = {
  ANNUAL_PLAN: 'annualPlan',
  FILTERS: 'filters',
  INTERVENTION_ZONE_DETAIL: 'interventionZoneDetail',
  COMMONERS: 'commoners',
  BUSINESS: 'business',
  COMMUNITY_DOCUMENTATION: 'communityDocumentation',
  DASHBOARD: 'dashboard',
  MAP: 'map',
  ACCOUNT_BOOK: 'accountBook',
  MULTISELECTION: 'multiselection',
  BATCH: 'batch'
};

export const FILTERS = {
  SPECIES: 'species',
  PRIORITIES: 'priorities',
  CATEGORIES: 'categories',
  YEARS: 'years',
  DONE: 'done',
  SXPUBLICA: 'sxpublica'
};

export const DONE_TYPES = {
  COMPLETE: 'complete',
  PARTIAL: 'partial',
  NONE: 'none'
};

export const ANNUAL_PLAN_STATUS = {
  COMPLETED: 'completed',
  DRAFT: 'draft',
  IN_PROCEDURE: 'in_procedure'
};

export const BUSINESS_CATEGORIES = {
  FURNITURE: 'furniture',
  FORESTRY: 'forestry',
  PAPER: 'paper',
  SAWMILL: 'sawmill',
  BOARD: 'board',
  OTHER: 'other'
};

export const DEFAULT_CATEGORY_GROUP_ICON = <IcAprovechamiento/>;
export const CATEGORY_GROUPS = [
  {
    id: 1,
    color: '#38331f',
    label: 'aproveitamento',
    icon: <IcAprovechamiento/>,
    background: '#EFECE9'
  },
  {
    id: 2,
    color: '#0b7741',
    label: 'selvícola',
    icon: <IcSelvicola/>,
    background: '#E5F3EE'
  },
  {
    id: 3,
    color: '#81b829',
    label: 'rexeneración',
    icon: <IcRegeneracion/>,
    background: '#F2F7ED'
  },
  {
    id: 4,
    color: '#253579',
    label: 'contra incendios',
    icon: <IcContraIncendios/>,
    background: '#EAEAF2'
  },
  {
    id: 5,
    color: '#562b54',
    label: 'infraestructura',
    icon: <IcInfraestructuras/>,
    background: '#EDF1F7'
  },
  {
    id: 6,
    color: '#b85431',
    label: 'outro',
    icon: <IcOtras/>,
    background: '#F7E8E5'
  },
  {
    id: 7,
    color: '',
    label: '',
    icon: <IcOtras/>,
    background: '#EFECE9'
  }
];
export const MAP_DETAILS = {
  especies: 'especies',
  annualPlan: 'annualPlan',
  business: 'business',
  forestal: 'forestal',
  patrimonio: 'patrimonio'
};
export const MAP_DETAIL_STYLES = [
  {
    label: 'Especies',
    thumbnail: 'mapstyles/leaf.svg',
    id: 'especies',
    colors: [
      {
        id: 2,
        name: 'Eucaliptus',
        type: 'fill',
        paint: {
          color: '#0b3286',
        },
      },
      {
        id: 4,
        name: 'Frondosas',
        type: 'fill',
        paint: {
          color: '#7e4a23'
        },
      },
      {
        id: 1,
        name: 'Pinus',
        type: 'fill',
        paint: {
          color: '#5d890b',
        },
      },
      {
        id: 3,
        name: 'Acacias',
        type: 'fill',
        paint: {
          color: '#860fc2',
        },
      },
      {
        id: 0,
        name: 'Raso',
        type: 'fill',
        paint: {
          color: '#f1ed15',
        },
      },
      {
        id: 999,
        name: 'Otros',
        type: 'fill',
        paint: {
          color: '#a6a6a6',
        },
      }
    ]
  },
  {
    id: 'annualPlan',
    label: 'Categorías',
    colors: [
      {
        id: 1,
        name: 'Aproveitamento',
        type: 'fill',
        paint: {
          color: '#38331f',
        },
      },
      {
        id: 2,
        name: 'Selvícola',
        type: 'fill',
        paint: {
          color: '#0b7741'
        },
      },
      {
        id: 3,
        name: 'Rexeneración',
        type: 'fill',
        paint: {
          color: '#81b829'
        },
      },
      {
        id: 4,
        name: 'Incendios',
        type: 'fill',
        paint: {
          color: '#253579'
        },
      },
      {
        id: 5,
        name: 'Infraestructura',
        type: 'fill',
        paint: {
          color: '#562b54'
        },
      },
      {
        id: 6,
        name: 'Outro',
        type: 'fill',
        paint: {
          color: '#b85431'
        },
      }
    ]
  },
  {
    id: 'business',
    thumbnail: 'mapstyles/business.svg',
    label: 'Empresas',
    colors: [
      {
        id: BUSINESS_CATEGORIES.FURNITURE,
        name: 'Mobles',
        type: 'fill',
        icon: <ChairIcon/>,
        paint: {
          color: '#074F57',
        },
      },
      {
        id: BUSINESS_CATEGORIES.FORESTRY,
        name: 'Silvicultura',
        type: 'fill',
        icon: <ForestIcon/>,
        paint: {
          color: '#00C49A'
        },
      },
      {
        id: BUSINESS_CATEGORIES.PAPER,
        name: 'Papel',
        type: 'fill',
        icon: <AutoStoriesIcon/>,
        paint: {
          color: '#F7996E'
        },
      },
      {
        id: BUSINESS_CATEGORIES.SAWMILL,
        name: 'Aserradero',
        type: 'fill',
        icon: <CarpenterIcon/>,
        paint: {
          color: '#0E9AAA'
        },
      },
      {
        id: BUSINESS_CATEGORIES.BOARD,
        name: 'Infraestructura',
        type: 'fill',
        icon: <LayersIcon/>,
        paint: {
          color: '#F45B69'
        },
      },
      {
        id: BUSINESS_CATEGORIES.OTHER,
        name: 'Outro',
        type: 'fill',
        icon: <AutoAwesomeIcon/>,
        paint: {
          color: '#976391'
        },
      }
    ]
  },
  {
    id: MAP_DETAILS.forestal,
    label: 'Forestal',
    thumbnail: 'mapstyles/forest.svg',
    colors: [
      {
        id: 1,
        name: 'Forestal MVMC',
        type: 'fill',
        paint: {
          color: '#5cb816',
        }
      }
    ]
  },
  {
    id: MAP_DETAILS.patrimonio,
    label: 'Patrimonio Cultural',
    thumbnail: 'mapstyles/heritage.svg',
    colors: [
      {
        id: '03ACS_202212_PC_BicContProt_BIC_AreaAmortecemento',
        name: 'BIC Contorno Protección | Area amortecemento',
        type: 'fill',
        paint: {
          color: '#166fb8',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicContProt_BIC_ContornoProteccion',
        name: 'BIC Contorno Protección | Contorno Protección',
        type: 'fill',
        paint: {
          color: '#a216b8',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_AreaIntegral',
        name: 'BIC Elementos | Area Integral',
        type: 'fill',
        paint: {
          color: '#9ab816',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_CaminosDeSantiago_Arqueoloxico',
        name: 'BIC Elementos | C.S. Arqueolóxico',
        type: 'line',
        paint: {
          color: '#d05459',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_CaminosDeSantiago_Complementario',
        name: 'BIC Elementos | C.S. Complementario',
        type: 'line',
        paint: {
          color: '#8c4750',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_CaminosDeSantiago_Principal',
        name: 'BIC Elementos | C.S. Principal',
        type: 'fill',
        paint: {
          color: '#fa0b2e',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_Elementos',
        name: 'BIC Elementos | Elementos',
        type: 'symbol',
        paint: {
          color: '#8bb2ce',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_BicEltos_BIC_Elementos_Incoados',
        name: 'BIC Elementos | Elementos Incoados',
        type: 'symbol',
        paint: {
          color: '#a6c4c9',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_CaminosDeSantiagoDelim_AreaDeAmortecemento',
        name: 'Camino de Santiago. Área de amortecemento',
        type: 'fill',
        paint: {
          color: '#770830',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_CaminosDeSantiago_SenDelimitar',
        name: 'Camino de Santiago. Sen delimitar',
        type: 'line',
        paint: {
          color: '#dc7891',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_Catalogo_ContornoProteccion',
        name: 'Catálogo. Contorno de Protección',
        type: 'fill',
        paint: {
          color: '#e57625',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_Catalogo_Elementos',
        name: 'Catálogo. Elementos',
        type: 'symbol',
        paint: {
          color: '#90b2ce',
          opacity: 0.3
        },
      },
      {
        id: '03ACS_202212_PC_PlansEspeciaisProteccion',
        name: 'Plans Especiais de protección',
        type: 'fill',
        paint: {
          color: '#47d6ea',
          opacity: 0.3
        },
      }
    ]
  },
];

export const APP_MODES = {
  DEFAULT: 'default',
  OFFLINE: 'offline',
  ANNUALPLAN: 'annualPlan'
};

export const FILE_TYPES = {
  DOCUMENT: 'document',
  IMAGE: 'image'
};

export const FILE_CONTENT_TYPES = {
  DOCUMENT: 'application/pdf',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  GEOPACKAGE: 'application/x-sqlite3'
};

export const FILE_READ_RECEIPT = {
  WITH_CONFIRMATION: 'with_confirmation',
  NO_CONFIRMATION: 'no_confirmation'
};

export const BACKGROUNDCOLOR_CATEGORIES = {
  1: '#EFECE9',
  2: '#E5F3EE',
  3: '#F2F7ED',
  4: '#EAEAF2',
  5: '#EDF1F7',
  6: '#F7EDEA',
};

export const EXECUTION_TYPES = {
  COMPLETE: {
    id: 'COMPLETE',
    label: 'Ejecución completa',
    color: 'secondary.main',
    icon: <Tooltip title='Ejecución completa' key='complete'><CheckCircleIcon
      sx={{color: 'secondary.main', m: 0}}/></Tooltip>
  },
  PARTIAL: {
    id: 'PARTIAL',
    label: 'Ejecución parcial',
    color: 'secondary.main',
    icon: <Tooltip title='Ejecución parcial' key='partial'><CheckCircleOutlineIcon
      sx={{color: 'secondary.main', m: 0}}/></Tooltip>
  },
  NEVER_DO: {
    id: 'NEVER_DO',
    label: 'No ejecutable',
    color: '#de0606',
    icon: <Tooltip title='No ejecutable' key='never_do'><CancelIcon sx={{color: '#de0606', m: 0}}/></Tooltip>
  }
};

export const ACCOUNT_STATES = {
  created: 'created',
  invited: 'invited',
  activated: 'activated',
  disabled: 'disabled'
};

export const PIE_INDICATOR_LABEL = {
  VOLUME_WITH_BARK_APROVEITAMENTO: 'Vol. con Corteza Aprovechamiento (m3)',
  VOLUME_WITHOUT_BARK_APROVEITAMENTO: 'Vol. sin Corteza Aprovechamiento (m3)',
  AREA_APROVEITAMENTO: 'Sup. Aprovechamiento por especie (Ha)',
  AREA: 'Sup. especie (Ha)',
  TREE_FEET: 'Pies (nº)',
  VOLUME_WITH_BARK: 'Vol. con Corteza (m3)',
  VOLUME_WITHOUT_BARK: 'Vol. sin Corteza (m3)',
};

export const PIE_INDICATOR_TYPE = {
  AREA: 'area',
  TREE_FEET: 'treeFeet',
  VOLUME_WITH_BARK: 'volumeWithBark',
  VOLUME_WITHOUT_BARK: 'volumeWithoutBark',
};

export const ELASTIC_MODULE_LABELS = {
  mass_variables: 'Variables de masa',
  technological_properties: 'Propiedades tecnológicas',
  other_estimates: 'Otras estimaciones',
  physiography_climate_soil: 'Fisiografía, clima, suelo'

};

export const ELASTIC_MODULE_GROUPS = {
  mass_variables: ['t', 'n', 'g', 'dc', 'do', 'hm', 'hc', 'ho', 'esb', 'cr', 'vcc', 'ih'],
  technological_properties: ['db_p', 'moe_est', 'moe_d', 'htm', 'hmtab'],
  other_estimates: ['rend', 'ce_superior', 'porc_estr', 'hum'],
  physiography_climate_soil: ['altitud', 'pendiente', 'orientacion', 'tm', 'pm']
};
export const ELASTIC_MODULE_PARAMS = {
  moe_est: {
    id: '12',
    resfor_label: null,
    label: 'M. elasticidad',
    units: 'MPa',
    map: true
  },
  db_p: {
    id: '16',
    resfor_label: null,
    label: 'Densidad básica',
    units: 'kg/m3',
    map: true
  },
  porc_estr: {
    id: 'nose',
    resfor_label: null,
    label: '% C18 o superior',
    units: '%',
    map: false
  },
  t: {
    id: '0',
    resfor_label: 'age',
    label: 'Edad',
    units: 'años',
    map: false
  },
  n: {
    id: '01',
    resfor_label: 'treeFeet',
    label: 'Nº pies',
    units: 'pies/ha',
    map: false //TODO: poner en true cuando esté disponible en geoserver
  },
  g: {
    id: '02',
    resfor_label: 'basometricArea',
    label: 'A. Basimétrica',
    units: 'm2/ha',
    map: true
  },
  dc: {
    id: '03',
    resfor_label: null,
    label: 'Ø medio cuadrático',
    units: 'cm',
    map: false //TODO: poner en true cuando esté disponible en geoserver
  },
  do: {
    id: '04',
    resfor_label: null,
    label: 'Ø dominante',
    units: 'cm',
    map: false
  },
  hm: {
    id: '05',
    resfor_label: 'height',
    label: 'Altura media',
    units: 'm',
    map: false
  },
  ho: {
    id: '07',
    resfor_label: null,
    label: 'Altura domin.',
    units: 'm',
    map: true
  },
  vcc: {
    id: '11',
    resfor_label: 'volumeWithBark',
    label: 'Vol. corteza',
    units: 'm3',
    map: true
  },
  altitud: {
    id: '31',
    resfor_label: null,
    label: 'Elevación nivel mar',
    units: 'msnm',
    map: true
  },
  pendiente: {
    id: '32',
    resfor_label: null,
    label: 'Pendiente terreno',
    units: '%',
    map: true
  },
  tm: {
    id: '4100',
    resfor_label: null,
    label: 'Tª media anual',
    units: 'ºC',
    map: false
  },
  pm: {
    id: '4400',
    resfor_label: null,
    label: 'Precip. media anual',
    units: 'mm',
    map: false
  }
};

export const PRIORITIES = [
  {
    id: undefined,
    label: '',
    color: '#fabada'
  },
  {
    id: 1,
    label: 'ALTA',
    color: '#fc5252'
  },
  {
    id: 2,
    label: 'MEDIA',
    color: '#c0791f'
  },
  {
    id: 3,
    label: 'BAJA',
    color: '#247a44'
  }
];


