import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

//MUI-ICONS

//GEOCOMPONENTS

//BIKENTA
import DialogPanel from '../../DialogPanel';
import Box from '@mui/material/Box';
import InputSelect from '../AddInterventionMenu/InputSelect';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import StepContent from '@mui/material/StepContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Radio, RadioGroup} from '@mui/material';
import Typography from '@mui/material/Typography';
import CalculationParamItem from './CalculationParamItem';

//UTILS

const MaderaPlusForm = ({
  maderaPlusAvailableSpecies,
  onClose,
  onCalculateWithParams,
  onCalculateWithoutParams
}) => {
  /*const [selectedSpecieId, setSelectedSpecieId] = useState(maderaPlusAvailableSpecies[0].id);*/
  const [selectedSpecieId, setSelectedSpecieId] = useState(27);

  const selectedSpecie = useMemo(() => (
    maderaPlusAvailableSpecies.find(specie => specie.id === selectedSpecieId)
  ), [selectedSpecieId]);

  const initialConfiguration = {
    calculateWithUserData: 'yes',
    specie: selectedSpecieId,
    params: {
      t: selectedSpecie.existences.age,
      n: selectedSpecie.existences.treeFeet,
      g: selectedSpecie.existences.basometricArea,
      vcc: selectedSpecie.existences.volumeWithBark,
      hm: selectedSpecie.existences.height
    }
  };
  
  const [activeStep, setActiveStep] = useState(0);
  const [calculationConfiguration, setCalculationConfiguration] = useState(initialConfiguration);
  const handleUserDataChange = (e) => {
    setCalculationConfiguration(
      {
        ...calculationConfiguration,
        calculateWithUserData: e.target.value
      }
    );
  };

  const handleSelectedSpecieChange = (id) => setSelectedSpecieId(Number(id));
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleClose = () => onClose && onClose();

  const handleCalculateWithoutParams = () => {
    onCalculateWithoutParams(selectedSpecie);
    handleClose();
  };
  const handleCalculateWithParams = () => {
    onCalculateWithParams();
    handleClose();
  };
  const handleItemChange = (id, value) => {
    setCalculationConfiguration(
      {
        ...calculationConfiguration,
        params: {
          ...calculationConfiguration.params,
          [id]: value
        }
      }
    );
  };

  const steps = [
    {
      id: 1,
      label: 'Especie',
      subLabel: 'Elige la especie sobre la que calcular el módulo de elasticidad.',
      content: <InputSelect
        value={selectedSpecieId}
        options={maderaPlusAvailableSpecies}
        onChange={handleSelectedSpecieChange}
      />
    },
    {
      id: 2,
      label: 'Datos',
      subLabel: '¿Quieres aportar algún dato?:',
      content: <RadioGroup
        value={calculationConfiguration.calculateWithUserData}
        onChange={handleUserDataChange}
        sx={{display: 'flex', flexDirection: 'row'}}
      >
        <FormControlLabel value='yes' control={<Radio size='small'/>} label='Sí' />
        <FormControlLabel value='no' control={<Radio size='small'/>} label='No'/>
      </RadioGroup>
    },
    {
      id: 3,
      label: 'Datos de entrada',
      subLabel: 'Selecciona los datos con los que quieres que se realice el cálculo. Puedes mofificar los valores haciendo click.',
      content: <Box sx={{my: 2, border: '1px solid lightgrey', borderRadius: 1, bgcolor: 'grey.100'}}>
        <CalculationParamItem id='t' label='Edad' units='años' defaultValue={calculationConfiguration.params.t} onItemChange={handleItemChange}/>
        <CalculationParamItem id='n' label='Nº pies' units='pies/ha' defaultValue={calculationConfiguration.params.n} onItemChange={handleItemChange}/>
        <CalculationParamItem id='g' label='A. Basimétrica' units='m2/ha' defaultValue={calculationConfiguration.params.g} onItemChange={handleItemChange}/>
        <CalculationParamItem id='vcc' label='Vol. corteza' units='años' defaultValue={calculationConfiguration.params.vcc} onItemChange={handleItemChange}/>
        <CalculationParamItem id='hm' label='Altura media' units='m' defaultValue={calculationConfiguration.params.hm} onItemChange={handleItemChange}/>
      </Box>
    }
  ];

  const stepButtons = useMemo(() => (
    <>
      <Button disabled={activeStep === 0} onClick={handleBack}>ATRÁS</Button>
      <Button
        id='add-maderaplus-menu-siguiente-anadir'
        variant='contained'
        color='secondary'
        onClick={ 
          (activeStep === 1 && calculationConfiguration.calculateWithUserData === 'no')
            ? handleCalculateWithoutParams
            : (activeStep === steps.length - 1) 
              ? handleCalculateWithParams
              : handleNext
        }
      >{((activeStep === 1 && calculationConfiguration.calculateWithUserData === 'no') || activeStep === steps.length - 1) ? 'CALCULAR' : 'SIGUIENTE'}
      </Button>
    </>
  ), [activeStep, calculationConfiguration]);

  return <DialogPanel
    isOpen={true}
    onClose={handleClose}
    label='MODULO MADERA+'
    fullWidth
  >
    <Stepper activeStep={activeStep} orientation='vertical' sx={{ml: 2}}>
      {
        steps.map(({label, subLabel, content, id}) => (
          <Step key={id}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {subLabel && <Typography>{subLabel}</Typography>}
              {content}
              <Box mt={1}>{stepButtons}</Box>
            </StepContent>
          </Step>    
        ))
      }
    </Stepper>
  </DialogPanel>;
};

MaderaPlusForm.propTypes = {
  maderaPlusAvailableSpecies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    existences: PropTypes.shape({
      age: PropTypes.number,
      treeFeet: PropTypes.number,
      height: PropTypes.number,
      basometricArea: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      biomass: PropTypes.number,
    })
  })),
  onClose: PropTypes.func.isRequired,
  onCalculateWithParams: PropTypes.func.isRequired,
  onCalculateWithoutParams: PropTypes.func.isRequired,
};

MaderaPlusForm.defaultProps = {
};

export default MaderaPlusForm;